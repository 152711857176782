import { WithChildren } from 'helpers/types'
import { createContext, useContext, useState } from 'react'

export interface AuthContextValue {
  isSigned: boolean
  signIn: () => void
  signOut: () => void
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined)

export function AuthProvider({ children }: WithChildren) {
  const [isSigned, setIsSigned] = useState(false)

  const signIn = () => {
    setIsSigned(true)
  }

  const signOut = () => {
    setIsSigned(false)
  }

  return (
    <AuthContext.Provider value={{ isSigned, signIn, signOut }}>{children}</AuthContext.Provider>
  )
}

export function useAuth(): AuthContextValue | undefined {
  return useContext(AuthContext)
}
