import axios from 'axios'
import { useWalletManagement } from 'features/web3OnBoard'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'

export default function Referral() {
  const { wallet } = useWalletManagement()
  const [copied, setCopied] = useState(false)
  const [referralCode, setReferralCode] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const { pathname } = useRouter()
  useEffect(() => {
    setShowPopup(pathname === '/airdrop')
  }, [pathname])
  const isMobileView = useMediaQuery('(max-width: 500px)')
  const copy = () => {
    if (referralCode) {
      void navigator.clipboard.writeText(referralCode).then(() => {
        setCopied(true)
      })
    } else {
      void axios
        .post(
          'https://api.matchfinance.io/referral/code',
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(`token-${wallet?.address}`)}`,
            },
          },
        )
        .then(({ data }: any) => {
          if (data.message === 'success') {
            setReferralCode(data.data.code)
            void navigator.clipboard.writeText(`${data.data.code}`).then(() => {
              setCopied(true)
            })
          }
        })
        .catch((e) => {
          console.log(e, e.statusCode, e.response)
          if (e.response && e.response.status === 401) {
            localStorage.setItem(`token-${wallet?.address}`, '')
            ;(window as any).onResign()
          }
        })
    }
  }

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), 3000)
  }, [copied])
  return (
    <>
      <Flex
        onClick={copy}
        sx={{
          marginTop: '24px',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
          borderRadius: '32px',
          background: '#fff',
          width: '114px',
          height: '49px',
          cursor: 'pointer',
          '.survey-text': {
            color: '#17344F',
          },
          '.hover-img': {
            display: 'none',
          },
          '&:hover': {
            background: '#5065E4',
            '.survey-text': {
              color: '#fff',
            },
            '.hover-img': {
              display: 'block',
            },
            '.default-img': {
              display: 'none',
            },
          },
        }}
      >
        <img className="default-img" src="/static/icons/icon_referrral.svg" />
        <img className="hover-img" src="/static/icons/icon_referrral_hovered.svg" />
        <Text
          className="survey-text"
          sx={{
            color: '#17344F',
            fontSize: '12px',
            fontWeight: '600',
            marginLeft: '8px',
          }}
        >
          Referral
        </Text>
      </Flex>

      {copied && (
        <Flex
          sx={{
            position: 'fixed',
            zIndex: '100',
            left: '50vw',
            transform: 'translateX(-50%)',
            top: '64px',
            background: '#F1F3FD',
            color: '#5065E4',
            fontSize: '16px',
            lineHeight: '24px',
            alignItems: 'center',
            padding: '16px',
            borderRadius: '12px',
          }}
        >
          <Text ml="10px">Referral code has been copied!</Text>
        </Flex>
      )}

      <Box
        sx={{
          width: '500px',
          height: '180px',
          position: 'fixed',
          right: showPopup ? '32px' : '-501px',
          transition: 'right 0.5s',
          bottom: '32px',
          background: 'url(/static/img/bg_referral_card.jpg)',
          backgroundSize: '100%',
          padding: '37px 0 0 250px',
          fontFamily: 'Poppins',
          borderRadius: '20px',
          '@media screen and (max-width: 500px)': {
            transform: 'scale(0.7)',
            transformOrigin: 'right top',
            right: showPopup ? '12px' : '-351px',
          },
        }}
        onClick={isMobileView ? copy : () => null}
      >
        <Image
          src="/static/icons/icon_close_2.svg"
          sx={{
            position: 'absolute',
            top: '13px',
            right: '13px',
            cursor: 'pointer',
          }}
          onClick={() => setShowPopup(false)}
        />
        <Text
          sx={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '30px',
          }}
        >
          Referral Points
        </Text>
        <Text sx={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '12px', lineHeight: '18px', mt: '5px' }}>
          Refer a Friend for 10% Extra Points!
        </Text>
        <Flex
          sx={{
            mt: '20px',
            width: '188px',
            height: '33px',
            borderRadius: '32px',
            border: '1px solid #5065E4',
            color: '#5065E4',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 600,
            fontFamily: 'body',
            cursor: 'pointer',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
            img: {
              content: 'url(/static/icons/copy-01.svg)',
              ml: '5px',
            },
            '&:hover': {
              background: '#5065E4',
              color: '#fff',
              img: {
                content: 'url(/static/icons/copy-02.svg)',
              },
            },
          }}
          onClick={isMobileView ? () => null : copy}
        >
          Copy Referral Link
          <img />
        </Flex>
      </Box>
    </>
  )
}
