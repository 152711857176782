import { useWalletManagement } from 'features/web3OnBoard'
import React, { useEffect, useState, useMemo } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'

import GlobalMask from './GlobalMask'

const discStyle = {
  color: '#D3CFF6',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 'normal',
  mt: '8px',
}
const contentStyle = {
  flexShrink: 0,
  width: '622px',
  '@media screen and (max-width: 500px)': {
    width: '100%',
  },
}
const nextStyle = { position: 'absolute', top: '331px', cursor: 'pointer' }

export default function () {
  const { wallet } = useWalletManagement()
  const [show, setShow] = useState(false)
  const [step, setStep] = useState(0)
  const isMobileView = useMediaQuery('(max-width: 500px)')
  const contentLeft = useMemo(() => `-${step * (isMobileView ? 360 : 622)}px`, [step, isMobileView])

  useEffect(() => {
    if (wallet?.address) {
      const showed = localStorage.getItem(`${wallet.address}-showed-qa`)
      if (!showed) {
        // localStorage.setItem(`${wallet.address}-showed-qa`, 'true')
        setShow(true)
      }
    } else {
      setShow(false)
    }
  }, [wallet?.address])

  const close = () => {
    localStorage.setItem(`${wallet?.address}-showed-qa`, 'true')
    setShow(false)
  }

  return show ? (
    <GlobalMask bg="white" sx={{ zIndex: 1000 }}>
      <Box
        sx={{
          borderRadius: '15px',
          background: '#7B7EFF',
          padding: '22.5px 45px',
          color: '#fff',
          fontFamily: 'Poppins',
          position: 'relative',
        }}
      >
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Text
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '100%',
              textShadow: '0px 0px 0px rgba(0, 0, 0, 0.10)',
            }}
          >
            esLBR mechanism Q&A
          </Text>
          <Image src="/static/icons/icon_close_3.svg" onClick={close} sx={{ cursor: 'pointer' }} />
        </Flex>

        <Box
          sx={{
            margin: '30px 33px',
            width: '622px',
            height: '523px',
            overflow: 'hidden',
            '@media screen and (max-width: 500px)': {
              width: '100%',
              margin: '30px 0',
            },
          }}
        >
          <Flex sx={{ position: 'relative', left: contentLeft, transition: 'left 0.5s' }}>
            <Box sx={contentStyle}>
              <Text sx={{ fontSize: '15px', fontWeight: 600, lineHeight: '25px' }}>
                What is esLBR and the problem?
              </Text>
              <Text as="p" sx={discStyle}>
                esLBR, which stands for escrowed LBR, is a nontrasferable token in lybra finance.
                Users can obtain esLBR by depositing either ETH or dLP. <br />
                <br />
                Users who acquire esLBR in Lybra Finance can either stake it to earn a revenue share
                or choose to vest it for 90 days to convert it into LBR tokens.
              </Text>
              <Image src="/static/img/qa/img_1.png" sx={{ mt: '56px', width: '100%' }} />
            </Box>
            <Box sx={contentStyle}>
              <Text sx={{ fontSize: '15px', fontWeight: 600, lineHeight: '25px' }}>
                What is mesLBR?
              </Text>
              <Text as="p" sx={discStyle}>
                mesLBR, short for "Match esLBR," is a liquid form of esLBR offered within Match
                Finance.When users deposit ETH or dLP to acquire mesLBR, it serves as evidence of
                their esLBR ownership within the Match Finance platform.
                <br />
                <br />
                Users have three ways to handle the mesLBR and theirs.
              </Text>
              <Image src="/static/img/qa/img_2.png" sx={{ mt: '30px' }} />
            </Box>
            <Box sx={contentStyle}>
              <Text sx={{ fontSize: '15px', fontWeight: 600, lineHeight: '25px' }}>
                What is the staking reward?
              </Text>

              <Flex sx={{ justifyContent: 'space-between', mt: '51px' }}>
                <Box>
                  <Image src="/static/img/qa/img_3_1.png" />
                  <Text
                    as="p"
                    sx={{
                      ...discStyle,
                      mt: '46px',
                      width: '274px',
                      '@media screen and (max-width: 500px)': {
                        width: '170px',
                      },
                    }}
                  >
                    <Text as="span" sx={{ color: '#fff', fontWeight: 700 }}>
                      Lybra Finance:
                    </Text>
                    <br />
                    step1: Buy{' '}
                    <Text as="span" sx={{ color: '#FBCC5F' }}>
                      LBR
                    </Text>{' '}
                    in Dex
                    <br />
                    step2: stake to esLBR to earn{' '}
                    <Text as="span" sx={{ color: '#FBCC5F' }}>
                      25%
                    </Text>{' '}
                    peUSD APR
                  </Text>
                </Box>
                <Box>
                  <Image src="/static/img/qa/img_3_2.png" />
                  <Text
                    as="p"
                    sx={{
                      ...discStyle,
                      mt: '46px',
                      width: '274px',
                      '@media screen and (max-width: 500px)': {
                        width: '170px',
                      },
                    }}
                  >
                    <Text as="span" sx={{ color: '#fff', fontWeight: 700 }}>
                      Match Finance:
                    </Text>
                    <br />
                    step1: Use{' '}
                    <Text as="span" sx={{ color: '#FBCC5F' }}>
                      0.7 LBR
                    </Text>{' '}
                    to buy{' '}
                    <Text as="span" sx={{ color: '#FBCC5F' }}>
                      1 mesLBR
                    </Text>
                    <br />
                    Step2: stake in Match Finance to earn{' '}
                    <Text as="span" sx={{ color: '#FBCC5F' }}>
                      48% APR
                    </Text>{' '}
                    (35% peUSD APR and 12% boosting APR)
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box sx={contentStyle}>
              <Text sx={{ fontSize: '15px', fontWeight: 600, lineHeight: '25px' }}>
                How to solve the problem?
              </Text>
              <Flex sx={{ mt: '34px', justifyContent: 'space-between' }}>
                <Box>
                  <Image src="/static/img/qa/img_4_1.png" />
                  <Text
                    sx={{
                      ...discStyle,
                      mt: '45px',
                      fontSize: '14px',
                      width: '282px',
                      '@media screen and (max-width: 500px)': {
                        width: '170px',
                      },
                    }}
                  >
                    DeFi farmers can participate in farming esLBR without any vesting period.
                  </Text>
                </Box>
                <Box>
                  <Image src="/static/img/qa/img_4_2.png" />
                  <Text
                    sx={{
                      ...discStyle,
                      mt: '45px',
                      fontSize: '14px',
                      width: '282px',
                      '@media screen and (max-width: 500px)': {
                        width: '170px',
                      },
                    }}
                  >
                    Buy esLBR with discount and enjoy boosting yield
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Flex sx={{ justifyContent: 'center', width: '89px' }}>
            <Image src={`/static/img/qa/1${step ? '' : '_active'}.svg`} />
          </Flex>
          <Box
            sx={{
              borderTop: '1px solid',
              flexGrow: 1,
              borderColor: step === 0 || step === 1 ? '#B9C2F4' : '#8798F4',
            }}
          />
          <Flex sx={{ justifyContent: 'center', width: '89px' }}>
            <Image src={`/static/img/qa/2${step !== 1 ? '' : '_active'}.svg`} />
          </Flex>
          <Box
            sx={{
              borderTop: '1px solid',
              flexGrow: 1,
              borderColor: step === 2 ? '#B9C2F4' : '#8798F4',
            }}
          />
          <Flex sx={{ justifyContent: 'center', width: '89px' }}>
            <Image src={`/static/img/qa/3${step !== 2 ? '' : '_active'}.svg`} />
          </Flex>
          <Box
            sx={{
              borderTop: '1px solid',
              flexGrow: 1,
              borderColor: step === 3 ? '#B9C2F4' : '#8798F4',
            }}
          />
          <Flex sx={{ justifyContent: 'center', width: '89px' }}>
            <Image src={`/static/img/qa/4${step !== 3 ? '' : '_active'}.svg`} />
          </Flex>
        </Flex>

        {step !== 3 && (
          <Image
            src="/static/img/qa/next.svg"
            sx={
              {
                ...nextStyle,
                right: '34px',
                '@media screen and (max-width: 500px)': {
                  right: '14px',
                },
              } as any
            }
            onClick={() => setStep(step + 1)}
          />
        )}
        {step !== 0 && (
          <Image
            src="/static/img/qa/next.svg"
            sx={
              {
                ...nextStyle,
                left: '34px',
                transform: 'rotate(180deg)',
                '@media screen and (max-width: 500px)': {
                  left: '14px',
                },
              } as any
            }
            onClick={() => setStep(step - 1)}
          />
        )}
      </Box>
    </GlobalMask>
  ) : null
}
