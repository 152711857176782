import { useWalletManagement } from 'features/web3OnBoard'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'

import Calculator from './Calculator'
import Questionnaire from './Questionnaire'
import Referral from './Referral'

export default function GlobalButtonGroup() {
  const { wallet } = useWalletManagement()
  const { pathname } = useRouter()
  const [maskShow, setShow] = useState(false)
  return pathname !== '/tge/rewards' ?(
    <Box
      sx={{
        position: 'fixed',
        bottom: '240px',
        right: 'calc(50vw - 760px)',
        textDecoration: 'none',
        zIndex: maskShow ? '100' : '2',
        '@media screen and (max-width: 1520px)': {
          right: '0',
        },
      }}
    >
      <Questionnaire />
      {wallet?.address && <Referral />}
      {/* {pathname === '/airdrop' && <Calculator onMaskShow={setShow} />} */}
    </Box>
  ) : null
}
