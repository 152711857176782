import axios from 'axios'
import GlobalMask from 'components/GlobalMask'
import { useWalletManagement } from 'features/web3OnBoard'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Grid, Heading, Image, Text } from 'theme-ui'
import { signData } from 'utils/match'
import { useAuth } from './context/AuthContextProvider'

async function getSignMessage(publicAddress: string) {
  const { data } = await axios.post<any>('https://api.matchfinance.io/auth/challenge', {
    publicAddress,
  })
  return data.data.challengeMessage
}
async function verifySign({ publicAddress, signature }: any) {
  const { data } = await axios.post<any>('https://api.matchfinance.io/auth/verify', {
    publicAddress,
    signature,
  })
  console.log(data)
  if (data.message !== 'success') throw new Error('verify sign failed')
  return data.data.token
}

function postReferral(referralCode: any, address: string) {
  return axios.post<any>(
    'https://api.matchfinance.io/referral',
    { referralCode },
    {
      headers: { Authorization: `Bearer ${localStorage.getItem(`token-${address}`)}` },
    },
  )
}

export default function SignTip() {
  const { query, replace, pathname } = useRouter()
  const { signIn } = useAuth()
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('unsigned')
  const { disconnect, wallet } = useWalletManagement()

  async function signToGetToken() {
    setStatus('signing')
    const address = wallet?.address
    try {
      const challengeMessage = await getSignMessage(address as string)
      // throw 'test'
      const sign = await signData(challengeMessage, address)
      const token = await verifySign({
        publicAddress: address,
        signature: sign,
      })
      signIn()
      localStorage.setItem(`token-${wallet?.address}`, token)
      setShow(false)
      setStatus('unsigned')
      window && (window as any).onSigned()
      if (query.refer) {
        postReferral(query.refer, wallet?.address as string)
          .then(({ data }) => {
            if (data.message === 'success') {
              void replace(pathname)
            }
          })
          .catch((e: any) => {
            if (
              e.response &&
              e.response.data &&
              e.response.data.message === 'You have already been referred'
            ) {
              void replace(pathname)
            }
          })
      }
    } catch (e: any) {
      // setStatus('failed')
      console.log('sign failed', e)
      if (e && e.code === 4001) {
        return setStatus('failed')
      }
      ;(window as any).signFailed = true
      setShow(false)
      setStatus('unsigned')
      // window && (window as any).onSigned()
    }
  }
  useEffect(() => {
    if ((window as any).signFailed) return
    if (wallet?.address) {
      const token = localStorage.getItem(`token-${wallet.address}`)
      setShow(!token)
      if (token && query.refer) {
        postReferral(query.refer, wallet.address)
          .then(({ data }) => {
            if (data.message === 'success') {
              void replace(pathname)
            }
          })
          .catch((e: any) => {
            if (
              e.response &&
              e.response.data &&
              e.response.data.message === 'You have already been referred'
            ) {
              void replace(pathname)
            }
          })
      }
    } else {
      setShow(false)
    }
  }, [wallet?.address])
  // useEffect(() => {
  //   window && ((window as any).onResign = () => setShow(true))
  // }, [])

  return (
    <>
      {show && (
        <GlobalMask sx={{ zIndex: 1000 }}>
          <Grid
            gap={3}
            sx={{ width: '400px', background: '#fff', padding: '16px', borderRadius: '20px' }}
          >
            <Box px={2}>
              <Heading variant="header4" sx={{ textAlign: 'center', pb: 1, pt: 3 }}>
                {status === 'failed' ? 'Sign Failed' : 'Welcome'}
              </Heading>
              {status !== 'failed' && (
                <Text mt={3} variant="paragraph3">
                  It looks like you're new to Match Finance or are using a new device to connect.
                  For Security please sign a message with your wallet to continue.
                </Text>
              )}
            </Box>
            <Button
              sx={{
                width: '80%',
                justifySelf: 'center',
                background: status === 'signing' ? '' : '#5065E4',
              }}
              disabled={status === 'signing'}
              onClick={() => (status === 'unsigned' ? signToGetToken() : setStatus('unsigned'))}
            >
              {status === 'signing'
                ? 'Signing'
                : status === 'unsigned'
                ? 'Sign message'
                : 'Try again'}
            </Button>
            <Button
              variant="textual"
              sx={{ width: '80%', justifySelf: 'center' }}
              onClick={disconnect}
            >
              Disconnect
            </Button>
          </Grid>
        </GlobalMask>
      )}
    </>
  )
}
