import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'

export default function GlobalMessage() {
  const [show, setShow] = useState(false)
  const [text, setText] = useState('Deposit (failed)')
  const [status, setStatus] = useState<'failed' | 'success' | 'waiting'>('failed')
  const [showDiscord, setShowDiscord] = useState<boolean>(false)
  const backgroundMap = {
    failed: '#EB5656',
    success: '#50A699',
    waiting: '#575CFE',
  }
  const [timeout, setTimeoutHandler] = useState<any>(null)
  useEffect(() => {
    ;(window as any).onMessage = (
      text: string,
      status: any,
      duration: number = 3000,
      showDiscord: boolean = false,
    ) => {
      setText(text)
      setStatus(status)
      setShow(true)
      setShowDiscord(showDiscord)
      if (timeout) clearTimeout(timeout)
      if (duration) {
        setTimeoutHandler(
          setTimeout(() => {
            setShow(false)
            setTimeoutHandler(null)
          }, duration),
        )
      }
    }
  }, [])
  return (
    <Box
      id="text"
      sx={{
        position: 'fixed',
        right: show ? 0 : '-450px',
        zIndex: 101,
        top: '128px',
        width: '446px',
        padding: '15px 30px',
        borderRadius: '16px',
        color: '#fff',
        background: backgroundMap[status],
        transition: 'right 0.5s',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        <Image src={`/static/icons/${status === 'failed' ? 'failed' : 'success'}_message.svg`} />
        <Box sx={{ ml: '15px', minWidth: '290px' }}>
          <Text sx={{ fontSize: '23px', fontWeight: 700, lineHeight: '36px' }}>{text}</Text>
          {showDiscord && (
            <Text sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', width: '290px' }}>
              Please contact the Match team on Discord
            </Text>
          )}
        </Box>
        <Image
          src="/static/icons/icon_close.svg"
          sx={{ width: '24px', cursor: 'pointer' }}
          onClick={() => setShow(false)}
        />
      </Flex>
    </Box>
  )
}
