import GlobalMask from 'components/GlobalMask'
import { Tooltip, useTooltip } from 'components/Tooltip'
import { useWalletManagement } from 'features/web3OnBoard'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'
import {
  fromWei,
  getLBROracle,
  getRewardContract,
  toWei,
  trimDecimals,
  getMatchContract,
} from 'utils/match'

export default function TokenToClaim() {
  const { wallet, chainId } = useWalletManagement()
  const [showClaim, setShowClaim] = useState(false)
  const [showGoStake, setShowGoStake] = useState(false)

  const [rewardContract, setRewardContract] = useState<any>(null)
  const [readRewardContract, setReadRewardContract] = useState<any>(null)
  const [matchPoolContract, setMatchPoolContract] = useState<any>(null)
  useEffect(() => {
    getRewardContract(false)
      .then((contract) => setRewardContract(contract))
      .catch(() => {})
    getRewardContract(true)
      .then((contract) => setReadRewardContract(contract))
      .catch(() => {})
    getMatchContract(false)
      .then((contract) => setMatchPoolContract(contract))
      .catch(() => {})
  }, [])

  const [mftAmount, setMftAmount] = useState(0)
  const [miningReward, setMiningReward] = useState(0)
  const [eUSDReward, setEUSDReward] = useState(0)
  const [lBRPrice, setLbrPrice] = useState(0)
  useEffect(() => {
    if ((window as any).onRewardsSet) {
      ;(window as any).onRewardsSet(mftAmount, miningReward)
    }
    ;(window as any).userRewards = mftAmount
    ;(window as any).miningReward = miningReward
  }, [mftAmount])
  useEffect(() => {
    if (wallet?.address && readRewardContract && rewardContract) {
      try {
        getRewards()
      } catch (e) {
        console.error(e)
      }
    }
  }, [wallet?.address, readRewardContract, rewardContract])

  async function getRewards() {
    const lbrOracle = await getLBROracle()
    const [dlpRewardPool, miningIncentive, lBRPriceWei] = [
      await readRewardContract.methods.dlpRewardPool().call(),
      await readRewardContract.methods.miningIncentive().call(),
      await lbrOracle.methods.latestRoundData().call(),
    ]
    const [
      dlpReward,
      miningReward,
      userSupplied,
      rewardPerTokenStore,
      userRewardPerTokenStore,
      userRewards,
    ] = [
      await readRewardContract.methods.earned(wallet?.address, dlpRewardPool).call(),
      await readRewardContract.methods.earned(wallet?.address, miningIncentive).call(),
      // await rewardContract.methods
      //   .earned(wallet?.address, '0xdf3ac4F479375802A821f7b7b46Cd7EB5E4262cC')
      //   .call(),
      await matchPoolContract.methods
        .supplied('0xa980d4c0C2E48d305b582AA439a3575e3de06f0E', wallet?.address)
        .call(),
      await readRewardContract.methods
        .rewardPerTokenStored('0xdf3ac4F479375802A821f7b7b46Cd7EB5E4262cC')
        .call(),
      await readRewardContract.methods
        .userRewardsPerTokenPaid('0xdf3ac4F479375802A821f7b7b46Cd7EB5E4262cC', wallet?.address)
        .call(),
      await readRewardContract.methods
        .userRewards('0xdf3ac4F479375802A821f7b7b46Cd7EB5E4262cC', wallet?.address)
        .call(),
    ]
    const eUSDReward =
      fromWei(userSupplied) * (fromWei(rewardPerTokenStore) - fromWei(userRewardPerTokenStore)) +
      fromWei(userRewards)
    // console.log('eusd reward', eUSDReward)
    // console.log('lBRPriceWei', lBRPriceWei)
    // console.log('dlpReward', dlpReward)
    // console.log('miningReward', miningReward)
    setMiningReward(fromWei(miningReward))
    setMftAmount(fromWei(dlpReward))
    setEUSDReward(eUSDReward)
    setLbrPrice(Number(lBRPriceWei[1]) / 1e8)
  }

  const mesLBRInU = useMemo(
    () => parseFloat(((mftAmount + miningReward) * lBRPrice).toFixed(2)),
    [mftAmount, miningReward, lBRPrice],
  )

  const [claimStatus, setStatus] = useState<string>('unclaimed')
  const toClaim = async () => {
    setStatus('claiming')
    try {
      await rewardContract.methods.getAllRewards().send({ from: wallet?.address, gas: 700000 })
      setMftAmount(0)
      setStatus('success')
      setShowClaim(false)
      setShowGoStake(true)
    } catch (e) {
      setStatus('failed')
      setTimeout(() => setStatus('unclaimed'), 3000)
    }
  }
  const closeModal = () => {
    setShowClaim(false)
    setStatus('unclaimed')
  }

  return (
    <Box sx={{ position: 'relative', fontFamily: 'body' }}>
      <Button
        onClick={() => setShowClaim(true)}
        sx={{
          width: '172px',
          height: '40px',
          background: '#5065E4',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 0,
          '@media screen and (max-width: 500px)': {
            width: '40px',
            border: '4px solid #fff',
            img: {
              width: '20px',
            },
          },
        }}
      >
        <Image src="/static/icons/icons-gift.svg" />
        <Text
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            ml: '8px',
            '@media screen and (max-width: 500px)': {
              display: 'none',
            },
          }}
        >
          Match Reward
        </Text>
      </Button>

      {showClaim && (
        <GlobalMask>
          <Box
            sx={{
              width: '396px',
              padding: '20px',
              background: '#fff',
              borderRadius: '16px',
              boxShadow: '0px 0px 30px 0px rgba(23, 52, 79, 0.25)',
            }}
          >
            <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Text sx={{ fontSize: '15px', lineHeight: '24px', fontWeight: 500 }}>
                Your Match Reward
              </Text>
              <Image
                src="/static/icons/icon_close_2.svg"
                sx={{ cursor: 'pointer' }}
                onClick={closeModal}
              />
            </Flex>

            {claimStatus === 'success' ? (
              <Text
                sx={{ color: '#17344F', fontSize: '16px', lineHeight: '22px', marginTop: '20px' }}
              >
                Claim Success!
              </Text>
            ) : (
              <>
                <Flex sx={{ mt: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Flex sx={{ alignItems: 'center' }}>
                    <Image src="/static/icons/tokens/dLP.svg" sx={{ width: '25px' }} />
                    <Text
                      sx={{ ml: '12px', fontSize: '18px', fontWeight: 500, lineHeight: '27px' }}
                    >
                      mesLBR
                    </Text>
                  </Flex>
                  <Text sx={{ fontSize: '16px', lineHeight: '24px' }}>
                    ${mesLBRInU.toLocaleString('en-US')}
                  </Text>
                </Flex>
                <Flex sx={{ mt: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Flex sx={{ alignItems: 'center' }}>
                    <Image src="/static/icons/tokens/eUSD.svg" sx={{ width: '25px' }} />
                    <Text
                      sx={{ ml: '12px', fontSize: '18px', fontWeight: 500, lineHeight: '27px' }}
                    >
                      eUSD
                    </Text>
                  </Flex>
                  <Text sx={{ fontSize: '16px', lineHeight: '24px' }}>
                    ${parseFloat(eUSDReward.toFixed(2)).toLocaleString('en-US')}
                  </Text>
                </Flex>
              </>
            )}
            {claimStatus === 'failed' && (
              <Text sx={{ color: 'red', fontSize: '16px', lineHeight: '22px', marginTop: '12px' }}>
                Claim Failed
              </Text>
            )}
            <Flex sx={{ marginTop: '20px', justifyContent: 'center' }}>
              {claimStatus !== 'success' && (
                <Button
                  variant="outline"
                  sx={{ width: '168px', height: '54px' }}
                  onClick={toClaim}
                  disabled={claimStatus === 'claiming' || (!mesLBRInU && eUSDReward <= 0)}
                >
                  {claimStatus === 'claiming' ? 'Claiming' : 'Claim'}
                </Button>
              )}
              {/* <Button
                sx={{ width: '168px', height: '54px', background: '#5065E4' }}
                // disabled={claimStatus === 'claiming'}
                onClick={() => {}}
                disabled
              >
                Go To Stake
              </Button> */}
            </Flex>
          </Box>
        </GlobalMask>
      )}

      {showGoStake && (
        <GlobalMask>
          <Box
            sx={{
              width: '640px',
              height: '440px',
              padding: '20px',
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              background: 'url(/static/img/background/goStake.png)',
              backgroundSize: 'cover',
            }}
          >
            <Image
              src="/static/icons/close.svg"
              sx={{
                width: '24px',
                height: '24px',
                marginBottom: '26px',
                alignSelf: 'flex-end',
                cursor: 'pointer',
              }}
              onClick={() => setShowGoStake(false)}
            ></Image>
            <Text
              sx={{
                width: '426px',
                fontSize: '32px',
                fontWeight: 700,
                fontFamily: 'Poppins',
                marginBottom: '40px',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              stake y<Image src="/static/icons/match-text-icon.svg"></Image>ur $Match <br></br> t
              <Image src="/static/icons/match-text-icon.svg"></Image> earn up t
              <Image src="/static/icons/match-text-icon.svg"></Image>{' '}
              <span
                style={{
                  color: '#000279',
                  fontSize: '40px',
                }}
              >
                200%
              </span>{' '}
              APR!
            </Text>
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '36px',
                marginBottom: '40px',
              }}
            >
              <Flex
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Text
                  sx={{
                    color: '#000279',
                    fontSize: '40px',
                    fontWeight: 700,
                    fontFamily: 'Poppins',
                  }}
                >
                  100%
                </Text>
                <Text
                  sx={{
                    color: '#D3CFF6',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Protocol Fee
                </Text>
              </Flex>
              <Flex
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Text
                  sx={{
                    color: '#000279',
                    fontSize: '40px',
                    fontWeight: 700,
                    fontFamily: 'Poppins',
                  }}
                >
                  100%
                </Text>
                <Text
                  sx={{
                    color: '#D3CFF6',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Bribe Reward
                </Text>
              </Flex>
              <Flex
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Text
                  sx={{
                    color: '#000279',
                    fontSize: '40px',
                    fontWeight: 700,
                    fontFamily: 'Poppins',
                  }}
                >
                  100%
                </Text>
                <Text
                  sx={{
                    color: '#D3CFF6',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Penalty Reward
                </Text>
              </Flex>
            </Flex>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '108px',
                height: '33px',
                padding: '7.5px 15px',
                borderRadius: '32px',
                border: '1px solid #fff',
                color: '#fff',
                fontSize: '12px',
                fontWeight: 600,
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowGoStake(false)
                window.open('/earn#meslbr-stake')
              }}
            >
              Stake
            </Box>
          </Box>
        </GlobalMask>
      )}
    </Box>
  )
}
