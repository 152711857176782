import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'
import {
  fromWei,
  getLybraMintPoolContract,
  getMatchTokenContract,
  getNativeTokenAmount,
  getTokenContract,
  getVLMathContract,
  getVLMathStakingContract,
  getVLMathVestingContract,
  toWei,
} from 'utils/match'

export default function BuyMatchButton() {
  const [price, setPrice] = useState(0)
  const getPrice = async () => {
    const [mintPoolContract, wethContract, matchTokenContract] = await Promise.all([
      getLybraMintPoolContract(),
      getTokenContract('weth'),
      getMatchTokenContract(true),
    ])
    const [ethPriceWei, ethInMatchPoolWei, matchInPoolWei] = await Promise.all([
      mintPoolContract.methods.getAssetPrice().call(),
      wethContract.methods.balanceOf('0x7a35Fde859f614E12fdc314A87622f189B7bcCAb').call(),
      matchTokenContract.methods.balanceOf('0x7a35Fde859f614E12fdc314A87622f189B7bcCAb').call(),
    ])
    const ethPrice = fromWei(ethPriceWei)
    const matchPriceInE = fromWei(ethInMatchPoolWei) / fromWei(matchInPoolWei)
    setPrice(matchPriceInE * ethPrice)
  }
  useEffect(() => {
    void getPrice()
  }, [])
  const toBuy = () =>
    window.open(
      'https://app.uniswap.org/tokens/ethereum/0xe0DcB3E02798D1c6a9650fe1381015ec34705153',
    )
  return (
    <Flex
      sx={{
        alignItems: 'center',
        padding: '4px 17px 4px 4px',
        borderRadius: '32px',
        background: '#fff',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.10)',
        fontFamily: 'Poppins',
        cursor: 'pointer',
      }}
      onClick={toBuy}
    >
      <Image src="/static/icons/match_logo.svg" sx={{ width: '32px' }} />
      <Text
        sx={{
          ml: '8px',
          color: '#5065E4',
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        ${price.toFixed(2)}
      </Text>
      <Box
        sx={{
          ml: '8px',
          padding: '5px 8px',
          height: '18px',
          color: '#fff',
          background: '#5065E4',
          borderRadius: '4px',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '8px',
        }}
      >
        Buy
      </Box>
    </Flex>
  )
}
