import { HeaderSelector } from 'components/HeaderSelector'
import { AppLink } from 'components/Links'
import { NavigationActions } from 'components/navigation/NavigationActions'
import {
  NavigationBranding,
  NavigationBrandingPill,
} from 'components/navigation/NavigationBranding'
import { NavigationMenu } from 'components/navigation/NavigationMenu'
import { NavigationMenuPanelLinkType } from 'components/navigation/NavigationMenuLink'
import { NavigationOrb } from 'components/navigation/NavigationMenuOrb'
import { NavigationMenuPanelType } from 'components/navigation/NavigationMenuPanel'
import { NavigationMobileMenu } from 'components/navigation/NavigationMobileMenu'
import { INTERNAL_LINKS } from 'helpers/applicationLinks'
import { useOutsideElementClickHandler } from 'helpers/useOutsideElementClickHandler'
import { useToggle } from 'helpers/useToggle'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Box, Container, Flex, Image, Text, ThemeProvider } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'
import { NavigationMenuDropdown } from './NavigationMenuDropdown'

interface NavigationProps {
  actions?: ReactNode
  brandingLink?: string
  links?: NavigationMenuPanelLinkType[]
  panels?: NavigationMenuPanelType[]
  pill?: NavigationBrandingPill
}

export const navigationBreakpoints = [531, 744, 1025, 1279]
export const navigationBreakpointsWithPixels = navigationBreakpoints.map(
  (breakpoint) => `${breakpoint}px`,
)

const airdropNavActiveStyle = {
  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
  color: '#fff',
  border: 'none',
  img: {
    content: 'url("/static/icons/airdrop.svg")',
  },
}
const airdropNavDefaultStyle = {
  border: '1px solid #5065E4',
  color: '#5065E4',
  img: {
    content: 'url("/static/icons/airdrop_default.svg")',
  },
}
const tgeNavActiveStyle = {
  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
  color: '#fff',
  border: 'none',
  img: {
    content: 'url("/static/icons/tge.svg")',
  },
}
const tgeNavDefaultStyle = {
  border: '1px solid #5065E4',
  color: '#5065E4',
  img: {
    content: 'url("/static/icons/tge_default.svg")',
  },
}
const matchNavActiveStyle = {
  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
}

const navButtonStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 500,
  width: '96px',
  height: '32px',
  borderRadius: '16px',
  '@media screen and (max-width: 500px)': {
    width: '32px',
  },
}

export function Navigation({
  actions,
  brandingLink = INTERNAL_LINKS.homepage,
  links,
  panels,
  pill,
}: NavigationProps) {
  const { pathname } = useRouter()
  const [isMobileMenuOpen, toggleIsMobileMenuOpen, setIsMobileMenuOpen] = useToggle(false)
  const ref = useOutsideElementClickHandler(() => setIsMobileMenuOpen(false))
  const isViewBelowL = useMediaQuery(`(max-width: ${navigationBreakpoints[2] - 1}px)`)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }
  const dropdownRef = useRef(null)
  const menuRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !menuRef.current?.contains(event.target)
      ) {
        // 点击事件发生在菜单外部，关闭菜单
        setIsDropdownOpen(false)
      }
    }

    // 添加点击事件监听器
    document.addEventListener('mousedown', handleClickOutside)

    // 在组件卸载时移除监听器
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef, setIsDropdownOpen])

  return (
    <ThemeProvider theme={{ breakpoints: navigationBreakpointsWithPixels }}>
      <Container
        as="header"
        variant="navigation"
        sx={{
          position: 'relative',
          display: 'flex',
          gridTemplateColumns: ['auto auto', null, null, 'auto auto auto', '30% 40% 30%'],
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '24px',
          mb: '32px',
          zIndex: 999,
          '@media screen and (max-width: 460px)': {
            mb: '24px',
          },
        }}
      >
        <NavigationBranding
          link={pathname !== '/tge/rewards' ? brandingLink : '/tge/rewards'}
          pill={pill}
        />
        {/* {!isViewBelowL && <NavigationMenu links={links} panels={panels} />} */}
        {pathname !== '/tge/rewards' ? (
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
              // ml: '120px',
              '@media screen and (max-width: 500px)': {
                ml: '0',
                mr: '12px',
                justifyContent: 'flex-end',
              },
            }}
          >
            {!isViewBelowL && (
              <AppLink
                href="/"
                sx={{
                  fontSize: '14px',
                  // color: '#596478',
                  fontWeight: 500,
                  padding: '4px',
                  ...(pathname === '/' ? matchNavActiveStyle : { color: '#596478' }),
                  '&:hover': matchNavActiveStyle,
                }}
              >
                Match
              </AppLink>
            )}
            {(!isViewBelowL || pathname !== '/earn') && (
              <AppLink
                href="/earn"
                sx={{
                  fontSize: '14px',
                  // color: '#596478',
                  fontWeight: 500,
                  padding: '4px',
                  ml: '40px',
                  ...(pathname === '/earn' ? matchNavActiveStyle : { color: '#596478' }),
                  '&:hover': matchNavActiveStyle,
                }}
              >
                Earn
              </AppLink>
            )}
            {!(isViewBelowL && pathname === '/airdrop2') && (
              <Flex
                ref={menuRef}
                className="menu-item"
                sx={{ alignItems: 'center', position: 'relative' }}
              >
                <AppLink
                  sx={{
                    fontSize: '14px',
                    // color: '#596478',
                    fontWeight: 500,
                    padding: '4px',
                    ml: '40px',
                    '@media screen and (max-width: 500px)': {
                      ml: pathname === '/earn' ? '0px' : '10px',
                    },
                    ...(pathname === '/airdrop2' || pathname === '/airdrop'
                      ? matchNavActiveStyle
                      : { color: '#596478' }),
                    '&:hover': matchNavActiveStyle,
                  }}
                  onClick={toggleDropdown}
                >
                  Airdrop
                </AppLink>
                <Image
                  src="/static/icons/arrow.svg"
                  sx={{ width: '20px', transform: isDropdownOpen ? '' : 'rotate(180deg)' }}
                ></Image>
                {isDropdownOpen && (
                  <Flex
                    ref={dropdownRef}
                    sx={{
                      flexDirection: 'column',
                      width: '179px',
                      height: '100px',
                      borderRadius: '16px',
                      border: '1px solid #FFF',
                      background: 'rgba(255, 255, 255, 0.35)',
                      backdropFilter: 'blur(12.824999809265137px)',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '40px',
                      left: '30px',
                      zIndex: '999',
                    }}
                  >
                    <Flex
                      sx={{
                        padding: '10px 0 10px 20px',
                        width: '159px',
                        // color: pathname === '/airdrop' ? '#5065E4' : '#5B6476',
                        color: '#5B6476',
                        // background:
                        //   pathname === '/airdrop' ? 'rgba(80, 101, 228, 0.10)' : 'transparent',
                        fontFamily: 'Helvetica Neue',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        alignItems: 'center',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          background: 'rgba(80, 101, 228, 0.10)',
                          color: '#5065E4 !important',
                        },
                      }}
                      onClick={() => {
                        window.open('/airdrop', '_self')
                      }}
                    >
                      Airdrop Season 1
                    </Flex>
                    <Flex
                      sx={{
                        padding: '10px 0 10px 20px',
                        width: '159px',
                        color: '#5B6476',
                        // background:
                        //   pathname === '/airdrop2' ? 'rgba(80, 101, 228, 0.10)' : 'transparent',
                        fontFamily: 'Helvetica Neue',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        alignItems: 'center',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          background: 'rgba(80, 101, 228, 0.10)',
                          color: '#5065E4 !important',
                        },
                      }}
                      onClick={() => window.open('/airdrop2', '_self')}
                    >
                      Airdrop Season 2
                    </Flex>
                  </Flex>
                )}
              </Flex>
            )}
            {!(isViewBelowL && pathname === '/tge') && (
              <AppLink
                href="/tge"
                sx={{
                  ml: '40px',
                  '@media screen and (max-width: 500px)': {
                    ml: pathname === '/' ? '10px' : '0px',
                  },
                }}
              >
                <Flex
                  sx={{
                    ...navButtonStyle,
                    ...(pathname === '/tge' ? tgeNavActiveStyle : tgeNavDefaultStyle),
                    '&:hover': tgeNavActiveStyle,
                  }}
                >
                  <Image
                    sx={{
                      mr: '5px',
                      '@media screen and (max-width: 500px)': {
                        mr: '0px',
                      },
                    }}
                  />
                  <Text
                    sx={{
                      '@media screen and (max-width: 500px)': {
                        display: 'none',
                      },
                    }}
                  >
                    Launch
                  </Text>
                </Flex>
              </AppLink>
            )}
          </Flex>
        ) : null}
        <NavigationActions>
          {actions}
          {/* {isViewBelowL && (
            <Box ref={ref}>
              <NavigationOrb icon="menu" iconSize={20} onClick={toggleIsMobileMenuOpen} />
              <NavigationMobileMenu
                close={() => {
                  setIsMobileMenuOpen(false)
                }}
                isOpen={isMobileMenuOpen}
                links={links}
                panels={panels}
              />
            </Box>
          )} */}
        </NavigationActions>
      </Container>
    </ThemeProvider>
  )
}
