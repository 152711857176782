import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'

import { AppLink } from './Links'

export default function Questionnaire() {
  return (
    <AppLink
      href="https://forms.gle/6aBYPA1tvbGLYKjg8"
      target="_blank"
    >
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
          borderRadius: '32px',
          background: '#fff',
          width: '114px',
          height: '49px',
          cursor: 'pointer',
          '.survey-text': {
            color: '#17344F',
          },
          '.hover-img': {
            display: 'none',
          },
          '&:hover': {
            background: '#5065E4',
            '.survey-text': {
              color: '#fff',
            },
            '.hover-img': {
              display: 'block',
            },
            '.default-img': {
              display: 'none',
            },
          },
        }}
      >
        <img className="default-img" src="/static/icons/questionnaire.svg" />
        <img className="hover-img" src="/static/icons/questionnaire_hover.svg" />
        <Text
          className="survey-text"
          sx={{
            color: '#17344F',
            fontSize: '12px',
            fontWeight: '600',
            marginLeft: '8px',
          }}
        >
          Survey
        </Text>
      </Flex>
    </AppLink>
  )
}
