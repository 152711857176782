import { staticFilesRuntimeUrl } from 'helpers/staticPaths'
import { useRouter } from 'next/router'
import React from 'react'
import { Box, Image } from 'theme-ui'

export function BackgroundLight() {
  const { pathname } = useRouter()
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        zIndex: -1,
        backgroundColor: 'white',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      >
        <Image
          sx={
            pathname !== '/tge/rewards'
              ? { width: '100%', minWidth: '400px', maxHeight: '900px' }
              : {
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  objectFit: 'cover',
                }
          }
          src={staticFilesRuntimeUrl(
            pathname !== '/tge/rewards' ? '/static/img/bg_1.png' : '/static/img/bg_2.jpg',
          )}
        />
      </Box>
    </Box>
  )
}
